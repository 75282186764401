import { Controller } from "stimulus"

export default class extends Controller {
  constructor() {
    this.options = {};
    this.inline = false;
    this.element = null;
    this.elements = null;
    this.disappearing = false;
    this.initialized = false;
    this.animations = [
      {name: 'fadeIn', disappear: 'fadeOut', reverse: null},
      {name: 'fadeOut', disappear: true, reverse: null},
      {name: 'fadeInUp', disappear: 'fadeOutUp', reverse: 'fadeInDown'},
      {name: 'fadeOutUp', disappear: true, reverse: 'fadeOutDown'},
      {name: 'fadeInDown', disappear: 'fadeOutDown', reverse: 'fadeInUp'},
      {name: 'fadeOutDown', disappear: true, reverse: 'fadeOutUp'},
      {name: 'fadeInLeft', disappear: 'fadeOutLeft', reverse: 'fadeInRight'},
      {name: 'fadeOutLeft', disappear: true, reverse: 'fadeOutRight'},
      {name: 'fadeInRight', disappear: 'fadeOutRight', reverse: 'fadeInLeft'},
      {name: 'fadeOutRight', disappear: true, reverse: 'fadeOutLeft'},
      {name: 'fadeInUpBig', disappear: 'fadeOutUpBig', reverse: 'fadeInDownBig'},
      {name: 'fadeOutUpBig', disappear: true, reverse: 'fadeOutDownBig'},
      {name: 'fadeInDownBig', disappear: 'fadeOutDownBig', reverse: 'fadeInUpBig'},
      {name: 'fadeOutDownBig', disappear: true, reverse: 'fadeOutUpBig'},
      {name: 'fadeInLeftBig', disappear: 'fadeOutLeftBig', reverse: 'fadeInRightBig'},
      {name: 'fadeOutLeftBig', disappear: true, reverse: 'fadeOutRightBig'},
      {name: 'fadeInRightBig', disappear: 'fadeOutRightBig', reverse: 'fadeInLeftBig'},
      {name: 'fadeOutRightBig', disappear: true, reverse: 'fadeOutLeftBig'},
      {name: 'bounceIn', disappear: 'bounceOut', reverse: null},
      {name: 'bounceOut', disappear: true, reverse: null},
      {name: 'bounceInUp', disappear: 'bounceOutUp', reverse: 'bounceInDown'},
      {name: 'bounceOutUp', disappear: true, reverse: 'bounceOutDown'},
      {name: 'bounceInDown', disappear: 'bounceOutDown', reverse: 'bounceInUp'},
      {name: 'bounceOutDown', disappear: true, reverse: 'bounceOutUp'},
      {name: 'bounceInLeft', disappear: 'bounceOutLeft', reverse: 'bounceInRight'},
      {name: 'bounceOutLeft', disappear: true, reverse: 'bounceOutRight'},
      {name: 'bounceInRight', disappear: 'bounceOutRight', reverse: 'bounceInLeft'},
      {name: 'bounceOutRight', disappear: true, reverse: 'bounceOutLeft'},
      {name: 'flipInX', disappear: 'flipOutX', reverse: 'flipInY'},
      {name: 'flipOutX', disappear: true, reverse: 'flipOutY'},
      {name: 'flipInY', disappear: 'flipOutY', reverse: 'flipInX'},
      {name: 'flipOutY', disappear: true, reverse: 'flipOutX'},
      {name: 'lightSpeedIn', disappear: 'lightSpeedOut', reverse: null},
      {name: 'lightSpeedOut', disappear: true, reverse: null},
      {name: 'rotateIn', disappear: 'rotateOut', reverse: null},
      {name: 'rotateOut', disappear: true, reverse: null},
      {name: 'rotateInDownLeft', disappear: 'rotateOutDownLeft', reverse: 'rotateInUpRight'},
      {name: 'rotateOutDownLeft', disappear: true, reverse: 'rotateOutUpRight'},
      {name: 'rotateInDownRight', disappear: 'rotateOutDownRight', reverse: 'rotateInUpLeft'},
      {name: 'rotateOutDownRight', disappear: true, reverse: 'rotateOutUpLeft'},
      {name: 'rotateInUpLeft', disappear: 'rotateOutUpLeft', reverse: 'rotateInDownRight'},
      {name: 'rotateOutUpLeft', disappear: true, reverse: 'rotateOutDownRight'},
      {name: 'rotateInUpRight', disappear: 'rotateOutUpRight', reverse: 'rotateInDownLeft'},
      {name: 'rotateOutUpRight', disappear: true, reverse: 'rotateOutDownLeft'},
      {name: 'rollIn', disappear: 'rollOut', reverse: null},
      {name: 'rollOut', disappear: true, reverse: null},
      {name: 'zoomIn', disappear: 'zoomOut', reverse: null},
      {name: 'zoomOut', disappear: true, reverse: null},
      {name: 'zoomInUp', disappear: 'zoomOutUp', reverse: 'zoomInDown'},
      {name: 'zoomOutUp', disappear: true, reverse: 'zoomOutDown'},
      {name: 'zoomInDown', disappear: 'zoomOutDown', reverse: 'zoomInUp'},
      {name: 'zoomOutDown', disappear: true, reverse: 'zoomOutUp'},
      {name: 'zoomInLeft', disappear: 'zoomOutLeft', reverse: 'zoomInRight'},
      {name: 'zoomOutLeft', disappear: true, reverse: 'zoomOutRight'},
      {name: 'zoomInRight', disappear: 'zoomOutRight', reverse: 'zoomInLeft'},
      {name: 'zoomOutRight', disappear: true, reverse: 'zoomOutLeft'},
      {name: 'slideInUp', disappear: 'slideOutUp', reverse: 'slideInDown'},
      {name: 'slideOutUp', disappear: true, reverse: 'slideOutDown'},
      {name: 'slideInDown', disappear: 'slideOutDown', reverse: 'slideInUp'},
      {name: 'slideOutDown', disappear: true, reverse: 'slideOutUp'},
      {name: 'slideInLeft', disappear: 'slideOutLeft', reverse: 'slideInRight'},
      {name: 'slideOutLeft', disappear: true, reverse: 'slideOutRight'},
      {name: 'slideInRight', disappear: 'slideOutRight', reverse: 'slideInLeft'},
      {name: 'slideOutRight', disappear: true, reverse: 'slideOutLeft'}
    ];

    let animationArray = [];
    this.animations.forEach((animation) => animationArray.push(animation.name));
    this.animateClasses = animationArray;
  }

  initiate() {}
  setOptions() {}


  appear() {}
  disappear() {}
  toggle() {}

  resetClasses() {}
  getAnimation() {}
  getClassListFor() {}
  animate() {}

  prepareTransition() { }
  transition() { }

  get name() {
    return this.targets.find("name").value
  }
}

