const logMessage = "ES6, Stimulus & Turbolinks with Jekyll on Webpack";
// console.debug(logMessage)
import Swiper from 'swiper/swiper-bundle';

import 'swiper/swiper-bundle.css';
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()

const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import * as Turbo from "@hotwired/turbo"
Turbo.start();

import "tev-turbo-animate";

import './base.css';
import './tailwind.css';

const fadeInTime = '0.4s'
const fadeOutTime= '0.3s'

const setViewHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // console.debug('set view height', `${vh}px`);
}
setViewHeight();

// document.addEventListener('turbo:request-start', () => {
//   console.debug('TURBO request start');
// });

// document.addEventListener('turbo:click', function() {
//   console.log('JC turbo:click');
// })

// document.addEventListener('turbo:click', async (e) => {
//   console.log('JC async turbo:click', e);
// })

// document.addEventListener('turbo:before-visit', function() {
//   console.log('JC turbo:before-visit');
// })

// document.addEventListener('turbo:before-visit', async (e) => {
//   console.log('JC async turbo:before-visit', e);
// })

// document.addEventListener('turbo:visit', function() {
//   console.log('JC turbo:visit');
// })

// document.addEventListener('turbo:visit', async (e) => {
//   console.log('JC turbo:visit', e);
// })

// document.addEventListener('turbo:submit-start', function() {
//   console.log('JC turbo:submit-start');
// })

// document.addEventListener('turbo:submit-start', async (e) => {
//   console.log('JC turbo:submit-start', e);
// })

// document.addEventListener('turbo:before-fetch-request', function() {
//   console.log('JC turbo:before-fetch-request');
// })

// document.addEventListener('turbo:before-fetch-request', async (e) => {
//   console.log('JC turbo:before-fetch-request', e);
// })

// document.addEventListener('turbo:before-fetch-response', function() {
//   console.log('JC turbo:before-fetch-response');
// })

// document.addEventListener('turbo:before-fetch-response', async (e) => {
//   console.log('JC turbo:before-fetch-response', e);
// })

// document.addEventListener('turbo:submit-end', function() {
//   console.log('JC turbo:submit-end');
// })

// document.addEventListener('turbo:submit-end', async (e) => {
//   console.log('JC turbo:submit-end', e);
// })

// document.addEventListener('turbo:before-cache', function() {
//   console.log('JC turbo:before-cache');
// })

// document.addEventListener('turbo:before-cache', async (e) => {
//   console.log('JC turbo:before-cache', e);
// })

// document.addEventListener('turbo:before-render', function() {
//   console.log('JC turbo:before-render');
// })

// document.addEventListener('turbo:before-render', async (e) => {
//   console.log('JC turbo:before-render', e);
// })

// document.addEventListener('turbo:before-stream-render', function() {
//   console.log('JC turbo:before-stream-render');
// })

// document.addEventListener('turbo:before-stream-render', async (e) => {
//   console.log('JC turbo:before-stream-render', e);
// })

// document.addEventListener('turbo:render', function() {
//   console.log('JC turbo:render');
// })

// document.addEventListener('turbo:render', async (e) => {
//   console.log('JC turbo:render', e);
// })

// document.addEventListener('turbo:load', function() {
//   console.log('JC turbo:load');
// })

// document.addEventListener('turbo:load', async (e) => {
//   console.log('JC turbo:load', e);
// })

document.addEventListener('turbo:load', function() {
  console.debug('JC turbo:load pre TurboAnimate.init fadein');
  TurboAnimate.init({ duration: fadeInTime, animation: 'fadein', element: document.querySelector('main.turbo-animate') });
  // console.debug('JC turbo:load post TurboAnimate.init fadein');

  var video = document.getElementById("video");

  if (video) {
    console.log("VIDEO found")
    video.onloadstart = function(){
      video.style.opacity = 0
      console.log("VIDEO loading")
        document.getElementById("spinner").style.display="block"
    }

    video.onplaying = function(){
        console.log("VIDEO playing")
        document.getElementById("spinner").style.display="none"
      video.classList.add("fade-in-item")
      video.style.opacity = 100
    }
  }

  setViewHeight();

  const swiper = new Swiper(".mySwiper", {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 7500,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  // initTE({ Carousel });
});

window.addEventListener('resize', () => {
  // We execute the same script as before
  setViewHeight();
});

// document.addEventListener('turbo:before-render', async (e) => {
//   console.log('JC turbo:before-render', e);
//   e.preventDefault()
//   let animatedMain = document.querySelector('main.turbo-animate')
//     animatedMain.classList.add('transition-out')
//   TurboAnimate.init({ duration: fadeOutTime, animation: 'fadeout', element: animatedMain });

//   setTimeout(function() {
//     e.detail.resume()
//     // console.debug('JC load pre set timeout', e);
//     // Turbo.visit(e.detail.url);
//     // console.debug('JC load post set timeout');
//   }, 100);
// })

document.addEventListener('turbo:before-visit', function(e) {
  // console.debug('JC turbo:before-visit');
  let animatedMain = document.querySelector('main.turbo-animate')

  if(!animatedMain.classList.contains('transition-out')) {
    animatedMain.classList.add('transition-out')

    // console.debug('JC turbo:before-visit pre TurboAnimate.init fadeout');
    TurboAnimate.init({ duration: fadeOutTime, animation: 'fadeout', element: document.querySelector('main.turbo-animate') });
    // console.debug('JC turbo:before-visit post TurboAnimate.init fadeout');

    setTimeout(function() {
      // console.debug('JC load pre set timeout', e);
      Turbo.visit(e.detail.url);
      // console.debug('JC load post set timeout');
    }, 100);

    e.preventDefault();
  }
});
